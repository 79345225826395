<template>
  <div class="my-top-menu">
    <img src='../../assets/imgs/logo3.png' class='logo-img'></img>
	<!--
	<div class="logo-img"></div>
	-->
    <div class="top-menu-content">
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        :router="false"
        text-color="#000"
        active-text-color="#027acd"
        @select="handleSelect"
      >
        <el-menu-item
          class="custom-menu-item"
          index="/"
          to="home"
        >
          <div>首页</div>
        </el-menu-item>
        <el-menu-item
          index="/product"
          @click="&quot;/product&quot;;"
          to="product"
          class="custom-menu-item"
          
        >
          <div>软件产品</div>
        </el-menu-item>
        <el-menu-item
          
          class="custom-menu-item"
          index="/news"
          to="news"
        >
          <div>企业新闻</div>
        </el-menu-item>
        <el-submenu class="custom-menu-item" index="/">
          <template slot="title">
            <span class="custom-menu-item">典型案例</span>
          </template>
          <SubMenu />
        </el-submenu>
        <el-menu-item
          class="custom-menu-item"
          index="/honor"
          @click="&quot;/honor&quot;;"
          to="honor"
          
        >
          <div>荣誉资质</div>
        </el-menu-item>
        <el-menu-item
          class="custom-menu-item"
        >
          <div>&nbsp;</div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="lianxi">
      <div class="dianhua"></div>
      <div class="num">400-0027-366</div>
    </div>
  </div>
</template>

<script>
import SubMenu from "@/views/components/SubMenu.vue";
export default {
  components: { SubMenu },
  data() {
    return {
      activeIndex: "/",
      theme1: "light",
      index: sessionStorage.getItem("index") || "1",
    };
  },
  props: {
    scroll: String,
  },
  mounted() {
    window.addEventListener("setItem", () => {
      this.index = sessionStorage.getItem("index");
    });
  },
  methods: {
    // 点击回到最底部
     scrollBottom() {
      setTimeout(() => {
        // 获取元素
        var element = document.getElementById("scroll");
        // 将滚动条置底
        element.scrollTop = element.scrollHeight;
        console.log("id=scroll的元素的滚动高", element.scrollTop);
      });
    },
    handleSelect(key, keyPath) {
      this.activeIndex = keyPath;
      // 路由跳转
      if (key === "/contact") {
        this.$router.push("/");
        this.scrollBottom();
      }else{
        this.$router.push(key);
      }
      
    },
    // jump() {
    //   this.$router.replace("/");
    // },
  },
};
</script>

<style scoped lang='less'>
@font-face {
  font-family: 'Impact';
  src: url('/src/assets/fonts/Impact.ttf');
}
.el-menu.el-menu--horizontal {
  border: none;
}

.el-dropdown-menu__item, .el-menu-item{
	padding:0 1.5rem;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #409eff;
  color: #303133;
  height: 8.4rem !important;
}
.el-menu--horizontal >.el-submenu /deep/ .el-submenu__title {
	  height: 8.4rem;
	  line-height: 8.4rem;
	  padding:0 2rem;
}

.my-top-menu {
  height: 8.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.logo-img {
  margin-top: 1.53rem;
  margin-left: 8.08rem;
  width: 11%;
  min-width: 9rem;
  height: 5.5rem;
}
.top-menu-content {
  //   flex: 1;
  margin-left: auto;
  .el-menu-demo {
	  height: 8.4rem;
    .custom-menu-item {
      font-size: 1.4rem;
      margin-left: 0;
	  height: 8.4rem;
	  line-height: 8.4rem;
    }
  }
}
.lianxi {
  width: 13%;
  min-width: 80px;
  height: 8.4rem;
  background: #103f83;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 8.4rem;
  .dianhua {
    width: 2.5rem;
    height: 2.5rem;
    background: url("../../assets/imgs/dianhua.png") no-repeat;
    background-size: 2.343rem 2.343rem;
    margin: 3rem 10px 0 0;
  }
  .num {
    color: #fff;
    font-size: 1.5rem;
    width: 59.89%;
    font-family: 'Impact';
  }
}
</style>
